import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';
import { FidgetSpinner } from 'react-loader-spinner';
import { TextField, InputAdornment } from '@mui/material'; 
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import {  Button, Dialog, DialogContent, DialogActions } from '@mui/material'; 

const LoginForm = ({ onLogin }) => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [showForgotUserPasswordPopup, setShowForgotUserPasswordPopup] = useState(false);
  const [forgotUserPasswordEmail, setForgotUserPasswordEmail] = useState(''); 
  const navigate = useNavigate();

  const handleLoginClick = async () => {
    setIsLoading(true);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/employeelogin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userId, password: password }),
      });
  
      if (response.ok) {
        const data = await response.json();
        
       
        localStorage.setItem('token', data.result.access_token);
        localStorage.setItem('manager_id', data.result.id);
        localStorage.setItem('organization_id', data.result.organization_id);
        localStorage.setItem('companyName', data.result.organization);
        localStorage.setItem('role', data.result.role);
        localStorage.setItem('employee_id', data.result.id);
        localStorage.setItem('first_name', data.result.first_name);  
        localStorage.setItem('last_name', data.result.last_name);   
        localStorage.setItem('usergender', data.result.gender); 
  
        onLogin(data.result.role);  
        toast.success('You have logged in successfully');
  
        if (data.result.role === 'Admin') {
          navigate('/admin');
        } else if (data.result.role === 'Auditor') {
          navigate('/auditor-feedback-check');
        } else if (data.result.role === 'Manager') {
          navigate('/manager-dashboard');
        } else {
          navigate('/');
        }
      } else {
        const errorData = await response.json();
        if (errorData.message === "Invalid credentials" || errorData.statusCode === 401) {
          toast.error('Please enter valid email/password');
        } else {
          toast.error('Invalid credentials');
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
      toast.error('Please enter valid email/password');
    } finally {
      setIsLoading(false);
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleForgotUserPasswordClick = () => {
    setShowForgotUserPasswordPopup(true); 
  };

  const handleCloseForgotUserPasswordPopup = () => {
    setShowForgotUserPasswordPopup(false); 
  };

  const handleSubmitForgotUserPassword = async () => {
    if (!forgotUserPasswordEmail) {
      toast.error('Please enter a valid email address.');
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(forgotUserPasswordEmail)) {
      toast.error('Please enter a valid email address.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/employeeForgotPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: forgotUserPasswordEmail }), 
      });
  
      const data = await response.json();
      if (response.ok) {
        toast.success('Verification mail sent to reset password.');
        handleCloseForgotUserPasswordPopup();
      } else {
        toast.error(data.message || 'Failed to send password reset email.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while sending the reset password email.');
    }
  };


  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner
              visible={true}
              height="80"
              width="80"
              ariaLabel="fidget-spinner-loading"
              backgroundColor="blue"
            />
          </div>
        </div>
      )}
      <div className="Login_Container">
        <div className="Login_Container-Left">
          <div className="Login_Text">Login into your account</div>
          <div className="Login_input-form">
            <div className="Login_input-fields">
              <div className="login-input-container">
                <TextField
                  className="Login_input-inputBox"
                  label="Email"
                  type="email"
                  variant="outlined"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="login-input-container">
                <TextField
                  className="Login_input-inputBox"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <span className="login-eye-icon" onClick={togglePasswordVisibility}>
                  //         {showPassword ? <FaEyeSlash /> : <FaEye />}
                  //       </span>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </div>
            </div>
          </div>

          <div className="Login_forget-password">
          <span 
              className="Login_forget-password-text" 
              onClick={handleForgotUserPasswordClick} 
              style={{ cursor: 'pointer', color: 'blue' }}
            >
              Forgot <span>Password?</span>
            </span>
          </div>

          <div className="Login_register-button">
            <div className="Login_Button" onClick={handleLoginClick}>
              <div className="Login_buttonText">Login Now</div>
            </div>
          </div>
        </div>

        <div className="Login_formRight">
          <img className="Login_formimage" src="./Images/login-background.png" alt="Login" />
        </div>
      </div>
      <Dialog
        open={showForgotUserPasswordPopup}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleCloseForgotUserPasswordPopup();
          }
        }}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogContent>
          <h3 className='user-reset-password-email-text'>Enter your Email to reset Password</h3>
          <TextField
            label="Enter your email"
            type="email"
            fullWidth
            value={forgotUserPasswordEmail}
            onChange={(e) => setForgotUserPasswordEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForgotUserPasswordPopup}>Cancel</Button>
          <Button onClick={handleSubmitForgotUserPassword} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginForm;
